<template>
 <ion-page>
		<spot-header :title="pageTitle + (!tablesData || !tablesData.currentProject ? '' : tablesData.currentProject.name)" :canGoHome="false && !isWaitingItems" :canGoBack="false && !isWaitingItems" @pressedGoHome="$router.push('/home')" @pressedGoBack="$router.push('/home')"></spot-header>

		<ion-content :fullscreen="true"> <!--v-if="isActive"-->
			<ion-progress-bar type="indeterminate" v-if="!isActive"></ion-progress-bar>
			<div id="actionArea" v-else>
				<web-socket-reader crReplacedChar="|" @readedBarcode="onReadedBarcode" @readedTag="onReadedTag" @readerError="onReaderError"></web-socket-reader>
				<ion-label color="tertiary" v-if="isWaitingItems">{{isWaitingItemsText}}</ion-label>
				<spot-select
					:ref="fields[field.location].name"
					:label="fields[field.location].text"
					:allignLabel="fields[field.location].allignLabel"
					:required="fields[field.location].required"
					:placeholder="fields[field.location].placeholder"
					:enabled="fields[field.location].enabled"
					:enabledExtraButtons="fields[field.location].enabledExtraButtons"
					:value="fields[field.location].value"
					:options=/*tablesData.documents*/tablesData.locations
					:optionField="fields[field.location].refField"
					:validationState="fields[field.location].validationState"
					:canAddItem="fields[field.location].canAddItem"
					:canRefreshList="fields[field.location].canRefresh"
					:canUnlock="fields[field.location].canUnlock"
					:selectedLabel="selectedText"
					:deselectLabel="deselectText"
					:selectLabel="selectText">
				</spot-select>
				<div class="flex-row-center-container">
					<spot-button
						fill="outline"
						size="small"
						color="danger"
						:enabled="true && !isWaitingItems"
						:expand="null"
						:text="suspendText"
						@clicked="exitInventoryDocument()">
					</spot-button>
					<spot-button
						fill="outline"
						size="small"
						color="success"
						:enabled="true && !isWaitingItems"
						:expand="null"
						:text="closeText"
						@clicked="closeInventoryDocument()">
					</spot-button>
				</div>
				<ion-item>
					<spot-input
						:ref="fields[field.search].name"
						:label="fields[field.search].text"
						:allignLabel="fields[field.search].allignLabel"
						:required="fields[field.search].required"
						:placeholder="fields[field.search].placeholder"
						:enabled="fields[field.search].enabled"
						:value="fields[field.search].value"
						:type="fields[field.search].inputType"
						:clearInput="true"
						@textChanged="changedSearch">
					</spot-input>
					<ion-button v-if="!isWaitingItems" size="default" slot="end" disabled="true" @click="createNewPickAndProjectRowFromItems(null)">
						<ion-icon :icon="duplicate" slot="start" size="default"></ion-icon>
						{{ newItemText }}
					</ion-button> <!-- @click="createNewProjectRow" @click="createNewPick"-->
				</ion-item>
				<ion-progress-bar type="indeterminate" v-if="!tablesData.picks || !tablesData.projectRows"></ion-progress-bar>
				<EasyDataTable
					:headers="headers"
					:items="tablesData.picks"
					:sort-by="sortBy"
					:sort-type="sortType"
					:search-field="searchFields"
					:search-value="fields[field.search].value"
					:empty-message="noItemsText"
					:body-row-class-name="bodyRowClassNameFunction"
					@click-row="editExistingProjectRow"
					multi-sort> <!-- :body-item-class-name="bodyItemClassNameFunction" show-index alternating" -->
					<template #item-actions="pick">
						<!--
						<ion-button disabled=false fill="outline" shape="round" size="small" slot="icon-only" @click="showExistingProjectRow(pick)">
							<ion-icon :icon="reader" slot="end" size="small"></ion-icon>
						</ion-button>
						<ion-button :color="pick.quantity_detected == pick.quantity ? 'success' : (pick.quantity_detected == 0 ? 'danger' : (pick.quantity_detected < pick.quantity ? 'warning' : 'tertiary'))" disabled=false fill="outline" shape="round" size="small" slot="icon-only" @click="editExistingProjectRow(pick)">
							<ion-icon :icon="brush" slot="end" size="small"></ion-icon>
						</ion-button>
						-->
						<ion-button :disabled="pick.project_rows.length == 0" color="dark" fill="outline" shape="round" size="small" slot="icon-only" @click="openAlert(pick)"> <!-- @click="deleteProjectRowsAndPick(pick) @click="deleteProjectRowsInPick(pick)" @click="deleteExistingPick(pick)" -->
							<ion-icon :icon="trash" slot="end" size="small"></ion-icon>
						</ion-button>
					</template>
					<template #expand="element">
						{{element.item.description}}
					</template>
				</EasyDataTable>
				<ion-modal class="modal-settings" ref="modal" backdrop-dismiss="false" :is-open="isOpenModal" @didDismiss="modalDismissed">
					<ion-content>
						<ion-toolbar>
							<ion-title> {{ modalTitle }}</ion-title>
						</ion-toolbar>
							<div lines="none" v-for="field in pickContents" :key="field.id" class="no-pad">
							<spot-input
								v-if="(field.inputType == 'text' || field.inputType == 'number')"
								:ref="field.name"
								:label="field.text"
								:allignLabel="field.allignLabel"
								:required="field.required"
								:placeholder="getPlaceHolder(field)"
								:enabled="field.enabled"
								:validationState="field.validationState"
								:furtherInfo="field.furtherInfo"
								:value="field.value"
								:type="field.inputType"
								@textChanged="changedTextField(field.id, $event)">
							</spot-input>
							<ion-textarea
								v-if="field.inputType == 'area'"
								:ref="field.name"
								:placeholder="getPlaceHolder(field)"
								:required="field.required"
								:disabled="!field.enabled"
								:auto-grow="true"
								:value="field.value">
							</ion-textarea>
							<spot-checkbox
								v-if="field.inputType == 'check'"
								:ref="field.name"
								:label="field.text"
								:allignLabel="field.allignLabel"
								:required="field.required"
								:enabled="field.enabled"
								:validationState="field.validationState"
								:value="field.value"
								@checkboxChanged="changedCheckboxField(field.id, $event)"
								@focus="$event.target.select()">
							</spot-checkbox>
						</div>
						<ion-label color="tertiary" v-if="isActingDb">{{isWaitingActingDbText}}</ion-label>
						<div class="flex-row-center-container">
							<!--
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="undoText"
								@clicked="closeModal(false)">
							</spot-button>
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="!isEmptyRequiredPickFields && modalReadOnly == false"
								:expand="null"
								:text="confirmText"
								@clicked="closeModal(true)">
							</spot-button>
							-->
							<ion-button fill="outline" size="large" color="primary" :disabled="false" @click="closeModal(false)">
								<ion-spinner v-if="isActingDb"></ion-spinner>
								<span v-else>{{ undoText }}</span>
							</ion-button>
							<ion-button fill="outline" size="large" color="primary" :disabled="isEmptyRequiredPickFields == true || modalReadOnly == true || isActingDb == true" @click="closeModal(true)">
								<ion-spinner v-if="isActingDb"></ion-spinner>
								<span v-else>{{ confirmText }}</span>
							</ion-button>
						</div>
					</ion-content>
				</ion-modal>
				<ion-modal class="modal-settings" ref="newItem" backdrop-dismiss="false" :is-open="isOpenNewItem" @didDismiss="newItemDismissed">
					<ion-content>
						<ion-toolbar>
							<ion-title> {{ modalTitle }}</ion-title>
						</ion-toolbar>
							<div lines="none" v-for="field in itemContents" :key="field.id" class="no-pad">
							<spot-input
								v-if="(field.inputType == 'text' || field.inputType == 'number')"
								:ref="field.name"
								:label="field.text"
								:allignLabel="field.allignLabel"
								:required="field.required"
								:placeholder="getPlaceHolder(field)"
								:enabled="field.enabled"
								:validationState="field.validationState"
								:furtherInfo="field.furtherInfo"
								:value="field.value"
								:type="field.inputType"
								@textChanged="changedNewItemTextField(field.id, $event)">
							</spot-input>
							<ion-textarea
								v-if="field.inputType == 'area'"
								:ref="field.name"
								:placeholder="getPlaceHolder(field)"
								:required="field.required"
								:disabled="!field.enabled"
								:auto-grow="true"
								:value="field.value">
							</ion-textarea>
							<spot-checkbox
								v-if="field.inputType == 'check'"
								:ref="field.name"
								:label="field.text"
								:allignLabel="field.allignLabel"
								:required="field.required"
								:enabled="field.enabled"
								:validationState="field.validationState"
								:value="field.value"
								@checkboxChanged="changedNewItemCheckboxField(field.id, $event)"
								@focus="$event.target.select()">
							</spot-checkbox>
							<spot-select
								v-if="field.inputType == 'select'"
								:ref="field.name"
								:label="field.text"
								:allignLabel="field.allignLabel"
								:required="field.required"
								:placeholder="getPlaceHolder(field)"
								:enabled="field.enabled && !modalReadOnly"
								:enabledExtraButtons="field.enabledExtraButtons && !modalReadOnly"
								:validationState="field.validationState"
								:value="field.value"
								:options="tablesData.items"
								:optionField="field.refField"
								:canAddItem="field.canAddItem"
								:canRefreshList="field.canRefresh"
								:canUnlock="field.canUnlock"
								:selectedLabel="selectedText"
								:deselectLabel="deselectText"
								:selectLabel="selectText"
								@selected="assignedSelectField(field.id, $event)"
								@unselected="resetSelectField(field.id)">
							</spot-select>
						</div>
						<ion-label color="tertiary" v-if="isActingDb">{{isWaitingActingDbText}}</ion-label>
						<div class="flex-row-center-container">
							<!--
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="undoText"
								@clicked="closeNewItem(false)">
							</spot-button>
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="!isEmptyRequiredItemFields && modalReadOnly == false"
								:expand="null"
								:text="confirmText"
								@clicked="closeNewItem(true)">
							</spot-button>
							-->
							<ion-button fill="outline" size="large" color="primary" :disabled="false" @click="closeNewItem(false)">
								<ion-spinner v-if="isActingDb"></ion-spinner>
								<span v-else>{{ undoText }}</span>
							</ion-button>
							<ion-button fill="outline" size="large" color="primary" :disabled="isEmptyRequiredItemFields == true || modalReadOnly == true || isActingDb == true" @click="closeNewItem(true)">
								<ion-spinner v-if="isActingDb"></ion-spinner>
								<span v-else>{{ confirmText }}</span>
							</ion-button>
						</div>
					</ion-content>
				</ion-modal>
				<ion-modal class="alert-settings" ref="alert" backdrop-dismiss="false" :is-open="isOpenAlert" @didDismiss="alertDismissed">
					<ion-content>
						<ion-toolbar>
							<ion-title> {{ confirmDeleteTitle }}</ion-title>
						</ion-toolbar>
						<div class="wrapper">
							<ion-label class="ion-text-wrap">{{ confirmDeleteMessage }}</ion-label>
							<br>
							<ion-label class="ion-text-wrap">{{	(tablesData.currentPick && tablesData.currentPick.item) ? tablesData.currentPick.item.description : '' }}</ion-label>
						</div>
						<div class="flex-row-center-container">
							<spot-button
								fill="outline"
								size="small"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="undoText"
								@clicked="closeAlert(false)">
							</spot-button>
							<spot-button
								fill="outline"
								size="small"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="confirmText"
								@clicked="closeAlert(true)">
							</spot-button>
						</div>
					</ion-content>
				</ion-modal>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>Test Area</div>
				<spot-button
					fill="outline"
					size="large"
					color="primary"
					:enabled="true"
					text="Simulate Scan"
					@clicked="onReadedBarcode('1111111111111','barcode')">
				</spot-button>
				<div v-for="field in fields" :key="field.id">
					{{ field.name }} = {{ field.value }}
				</div>
			</div>
		</ion-content>

 </ion-page>
</template>

<style scoped>
	.modal-settings {
		--height: 80%;
		--width: 90%;
		--border-radius: 16px;
		--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	}
	.alert-settings {
		--height: 50%;
		--width: 90%;
		--border-radius: 16px;
		--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	}
	.wrapper {
		padding-left: 3vw;
		padding-right: 3vw;
	}
	ion-modal::part(backdrop) {
		background: var(--ion-color-medium);
		opacity: 1;
	}
	ion-modal ion-toolbar {
		--background: var(--ion-color-primary);
		--color: white;
	}
	ion-textarea {
		font-size: 12px;
		margin-top: 5px;
		margin-bottom: 0px;
		--padding-top: 0px;
		--padding-bottom: 0px;
		--inner-padding-top: 0px;
		--inner-padding-bottom: 0px;
		--min-height: 0px
	}
	.flex-row-center-container {
		margin-top: 0.2em;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.no-pad {
		margin-top: 0px;
		margin-bottom: 0px;
		--padding-top: 0px;
		--padding-bottom: 0px;
		--inner-padding-top: 0px;
		--inner-padding-bottom: 0px;
		--min-height: 0px
	}
	*:deep(ion-checkbox) {
		margin-top: 0px;
		margin-bottom: 0px;
	}
</style>
<style>
	.empty-row {
		--easy-table-body-row-background-color: var(--ion-color-danger); /*#f56c6c;*/
	}
	.partial-row {
		--easy-table-body-row-background-color: var(--ion-color-warning); /*#ddc229;*/
	}
	.completed-row {
		--easy-table-body-row-background-color: var(--ion-color-success); /*#67c23a;*/
	}
	.exceeded-row {
		--easy-table-body-row-background-color: var(--ion-color-tertiary); /*#0d9de0;*/
	}
	.prominence-column {
		font-size: 20px;
	}
</style>

<script>
import { IonButton, IonContent, IonIcon, IonItem, IonLabel, IonModal, IonPage, IonProgressBar, IonSpinner, IonTextarea, IonTitle, IonToolbar } from '@ionic/vue';
import { /*brush, */duplicate, /*reader, */trash } from 'ionicons/icons';
import { defineComponent } from 'vue';
import SpotButton from '../components/SpotButton.vue';
import SpotCheckbox from '../components/SpotCheckbox.vue';
import SpotSelect from '../components/SpotSelect.vue';
import SpotInput from '../components/SpotInput.vue';
import SpotHeader from "../components/SpotHeader.vue";
import WebSocketReader from '../components/WebSocketReader.vue';
import { checkUserSession, confirmYesNo, settings, showMsgError, showMsgInfo, showMsgWarning, writeLog } from '../plugins/common.js';
import { createPick, createProjectRow, deletePick, deleteProjectRow, getDocument, getItems, getPick, getProject, getProjectRow, searchAdditionalBarcode, searchItem, searchLocation, searchPicksWithDocuments, searchProjectRows, tablesData, updateDocument, updateProjectRow } from '../plugins/customDatabase.js';

	export default defineComponent({
		name: "ManageMixedInventory",
		props: {
			pageTitle: { type: String, default: 'Gestisci ' }, //'Gestisci Inventario'
			noItemsText: { type: String, default: 'Nessun Articolo presente' },
			newItemText: { type: String, default: 'Nuovo' }, //'Nuovo Articolo'
			editItemText: { type: String, default: 'Modifica Articolo'}, //'Modifica Articolo: '
			showItemText: { type: String, default: 'Visualizza Articolo'}, //'Visualizza Articolo: '
			isWaitingItemsText: { type: String, default: 'Caricamento Articoli... Attendere'},
			isWaitingActingDbText: { type: String, default: 'Aggiornamento liste in corso... Attendere'},
			suspendText: { type: String, default: 'Esci/Sospendi' },
			closeText: { type: String, default: 'Concludi' },
			undoText: { type: String, default: 'Annulla' },
			confirmText: { type: String, default: 'Conferma' },
			unselected: { type: String, default: 'Nessuno' },
			selectedText: { type: String, default: 'Selezionato' },
			deselectText: { type: String, default: 'annulla' },
			selectText: { type: String, default: 'seleziona' },
			messageSearchItemTitle: { type: String, default: 'Ricerca Articolo' },
			messageSearchItemNotFound: { type: String, default: 'Articolo non trovato!' },
			messageTitleCheckTAG: { type: String, default: 'Controllo TAG' },
			warnMultipleTAG: { type: String, default: 'Letto più di un TAG. Avvicinarsi solo al TAG da leggere e riprovare' },
			warnInavlidTAG: { type: String, default: 'TAG letto solo in parte. Riprovare a leggere' },
			confirmDeleteTitle: { type: String, default: 'Conferma Cancellazione' },
			confirmDeleteMessage: { type: String, default: 'Confermi la cancellazione dell\'articolo corrente?' },
			messageTitleReader: { type: String, default: 'Lettura Barcode/TAG' },
			errorReadingReader: { type: String, default: 'Errore nella lettura del barcode/tag' },
			messageTitleInsert: { type: String, default: "Inserimento Nuovo Collo" },
			confirmExitTitle: { type: String, default: 'Conferma Uscita' },
			confirmCloseMessage: { type: String, default: 'Confermi di terminare la rilevazione anche se i dati rilevati sembrano diversi da quelli previsti?' },
			confirmExitMessage: { type: String, default: 'Confermi la sospensione della rilevazione (sarà comunque possibile rientrare in un secondo momento)?' },
			messageTitleInvalidQuantity: { type: String, default: "Quantità non valida" },
			warnInavlidQuantity: { type: String, default: 'La Quantità inserita sommata alla Quantità rilevata in precedenza è superiore alla Quantità Prevista!' },
		},
		components: {
			IonPage,
			IonContent,
			IonProgressBar,
			IonLabel,
			IonItem,
			IonButton,
			IonIcon,
			IonModal,
			IonToolbar,
			IonTitle,
			IonSpinner,
			IonTextarea,
			SpotHeader,
			SpotSelect,
			SpotButton,
			SpotInput,
			SpotCheckbox,
			WebSocketReader,
		},
		data: () => ({
			isActive: null,
			field: {
				/*document*/location: 0,
				search: 1,
			},
			fields: [
			{ id: 0, name: /*'document'*/'location', text: 'Ubicazione:', enabled: false, enabledExtraButtons: false, allignLabel: true, placeholder: 'Seleziona Ubicazione', emptyValue: 'Nessuno', inputType: 'select', defaultValue: null, value: null, required: true, refField: 'name', validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
			{ id: 1, name: 'search', text: 'Cerca: ', enabled: true, allignLabel: true, placeholder: 'Cerca Articolo', inputType: 'text', defaultValue: '', value: null, required: false, refField: null },
			],
			searchFields: ['item.code','item.description'],
			headers: [
				// { text: 'Ubicazione', value: 'location.name', sortable: true },
				{ text: 'Articolo', value: 'item.code', sortable: true },
				{ text: 'UM', value: 'item.measure_unit.name', sortable: false },
				// { text: 'Previsti', value: 'item.quantity', sortable: false },
				{ text: 'Previsti', value: 'quantity', sortable: false },
				// { text: 'Rilevati', value: 'quantity', sortable: false },
				{ text: 'Rilevati', value: 'quantity_detected', sortable: false },
				{ text: 'Azioni', value: 'actions', sortable: false }
			],
			sortType: ['asc', 'asc'],
			sortBy: ['location.name','item.code'],
			pickContent: {
				id: 0,
				code: 1,
				description: 2,
				expectedQuantity: 3,
				quantity: 4,
			},
			pickContents: [
				{ id: 0, name: 'id', text: 'Id', enabled: false, placeholder: 'Inserisci ID', inputType: "number", defaultValue: null, allignLabel: true, value: null, required: false, furtherInfo: null, validationState: null },
				{ id: 1, name: 'code', text: 'Articolo', enabled: false, placeholder: 'Inserisci Codice Articolo', inputType: 'text', defaultValue: null, allignLabel: true, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 2, name: 'description', text: 'Descrizione', enabled: false, placeholder: 'Inserisci Descrizione', inputType: 'area', defaultValue: null, allignLabel: false, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 3, name: 'expectedQuantity', enabled: false, text: 'Quantità Prevista', placeholder: 'Inserisci Quantità Prevista', inputType: 'number', defaultValue: null, allignLabel: false, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 4, name: 'quantity', text: 'Quantità', enabled: true, placeholder: 'Inserisci Quantità', inputType: 'number',  defaultValue: null, allignLabel: true, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
			],
			itemContent: {
				search: 0,
				code: 1,
				description: 2,
				expectedQuantity: 3,
				quantity: 4,
			},
			itemContents: [
				{ id: 0, name: 'search', text: 'Cerca', placeholder: 'Cerca Articolo', inputType: "select", enabled: true, enabledExtraButtons: false, allignLabel: true, validationState: null, furtherInfo: null, defaultValue: null, value: null, required: true, refField: 'description', canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 1, name: 'code', text: 'Articolo', enabled: false, placeholder: 'Inserisci Codice Articolo', inputType: 'text', allignLabel: true, defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 2, name: 'description', text: 'Descrizione', enabled: false, placeholder: 'Inserisci Descrizione', inputType: 'area', allignLabel: false, defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 3, name: 'expectedQuantity', enabled: false, text: 'Quantità Prevista', placeholder: 'Inserisci Quantità Prevista', inputType: 'number', allignLabel: false, defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 4, name: 'quantity', text: 'Quantità', enabled: true, placeholder: 'Inserisci Quantità', inputType: 'number',  allignLabel: true, defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
			],
			isOpenModal: false,
			isOpenNewItem: false,
			isOpenAlert: false,
			isWaitingItems: false,
			modalTitle: null,
			modalReadOnly: null,
			actionDbCreate: null,
			isFree: null,
			isPicking: null,
			projectId: null,
			documentId: null,
			locationId: null,
			lastBarcode: null,
			lastTag: null,
			assignRemaingQuantity: false,
			allowExceededQuantity: true,
			allowNegativeQuantity: false,
			isStartedRowDeleteAction: false,
			isActingDb: false,
			// brush,
			duplicate,
			// reader,
			trash,
			settings,
			checkUserSession,
			confirmYesNo,
			showMsgError,
			showMsgInfo,
			showMsgWarning,
			writeLog,
			createPick,
			createProjectRow,
			deletePick,
			deleteProjectRow,
			getDocument,
			getItems,
			getPick,
			getProject,
			getProjectRow,
			searchAdditionalBarcode,
			searchItem,
			searchLocation,
			searchPicksWithDocuments,
			searchProjectRows,
			updateDocument,
			updateProjectRow,
			tablesData,
		}),
		async ionViewWillEnter() {
			this.getUrlParamsAndQuery();
			this.setDefault();
			await this.getAllTablesData();
			this.isActive = true;
		},
		ionViewWillLeave() {
			this.isOpenModal = false;
			this.isOpenNewItem = false;
			this.isOpenAlert = false;
			this.isWaitingItems = false;
			this.isActive = false;
		},
		computed: {
			isEmptyRequiredPickFields() {
				let ret = false;
				this.pickContents.forEach(element => {if (element.required && !element.value && element.id > 0) ret = true});
				return ret;
			},
			isEmptyRequiredItemFields() {
				let ret = false;
				this.itemContents.forEach(element => {if (element.required && !element.value && element.id > 0) ret = true});
				return ret;
			},
			/*
			isInventoryClean() {
				let ret = true;
				// if (this.tablesData.projectRows && this.tablesData.projectRows.length > 0) ret = false;
				this.tablesData.picks.forEach(element => {if (element.quantity_detected > 0) ret = false});
				return ret;
			},
			isInventoryFinished() {
				let ret = true;
				// this.tablesData.projectRows.forEach(element => {if (element.quantity != element.item.quantity) ret = false});
				if (!this.allowExceededQuantity) {
					this.tablesData.picks.forEach(element => {if (element.quantity != element.quantity_detected) ret = false});
				}
				else {
					this.tablesData.picks.forEach(element => {if (element.quantity > element.quantity_detected) ret = false});
				}
				return ret;
			},
			*/
			checkQuantity() {
				let ret = true;
				if (!this.allowExceededQuantity) {
					// if ((parseFloat(this.tablesData.currentProjectRow.quantity) + parseFloat(this.pickContents[this.pickContent.quantity].value)) > parseFloat(this.tablesData.currentProjectRow.item.quantity)) {
						if ((parseFloat(this.tablesData.currentPick.quantity_detected) + parseFloat(this.pickContents[this.pickContent.quantity].value)) > parseFloat(this.tablesData.currentPick.quantity)) {
							this.showMsgWarning(this.messageTitleInvalidQuantity, this.warnInavlidQuantity);
						ret = false;
					}
				}
				return ret;
			},
		},
		setup() {
			const bodyRowClassNameFunction = (element) => {
				/*
				if (parseFloat(element.quantity) == parseFloat(element.item.quantity)) return 'completed-row'
				else if(parseFloat(element.quantity) == parseFloat(0)) return 'empty-row'
				else if(parseFloat(element.quantity) < parseFloat(element.item.quantity)) return 'partial-row'
				else return 'exceeded-row';
				*/
				if (parseFloat(element.quantity_detected) == parseFloat(element.quantity)) return 'completed-row'
				else if(parseFloat(element.quantity_detected) == parseFloat(0)) return 'empty-row'
				else if(parseFloat(element.quantity_detected) < parseFloat(element.quantity)) return 'partial-row'
				else return 'exceeded-row';
			}
			const bodyItemClassNameFunction = (column) => {
				// if (column === 'quantity' || column === 'item.quantity') return 'prominence-column';
				if (column === 'quantity' || column === 'quantity_detected') return 'prominence-column';
				else return '';
			};
			return {
				bodyRowClassNameFunction,
				bodyItemClassNameFunction,
			};
		},
		methods: {
			// Settings & StartUp
			getUrlParamsAndQuery() {
				this.isFree = (/true/i).test(this.$route.query.free);
				this.isPicking = (/true/i).test(this.$route.query.picking);
				//this.locationId = parseInt(this.$route.query.location);
				this.documentId = parseInt(this.$route.query.document);
				this.projectId = this.$route.params.id;
			},
			setDefault() {
				this.tablesData.items = [];
				this.fields.forEach(element => element.value = element.defaultValue);
				this.isOpenModal = false;
				this.isOpenNewItem = false;
				this.isOpenAlert = false;
				this.isWaitingItems = false;
				this.modalTitle = null;
				this.modalReadOnly = null;
				this.actionDbCreate = null;
				this.lastBarcode = null;
				this.lastTag = null;
				this.isStartedRowDeleteAction = false;
				this.isActingDb = false
			},
			getPlaceHolder(element) {
				return ((this.settings.showFieldsPlaceholder) ? (element.required ? element.placeholder : this.unselected ) : '')
			},
			// Database
			async getAllTablesData(isFirstLoad = true) {
				if (isFirstLoad) {
					await this.getProject(this.projectId);
					await this.getDocument(this.documentId);
					await this.searchLocation(this.tablesData.currentDocument.name);
					this.fields[this.field.location].value = this.tablesData.currentLocation
					this.locationId = this.tablesData.currentLocation.id;
				}
				// this.tablesData.projectRows = [];
				// await this.searchProjectRows(this.projectId, this.locationId);
				// this.tablesData.picks = [];
				// await this.searchPicks(this.projectId, this.locationId);
				await this.searchPicksWithDocuments(this.projectId, this.documentId);
			},
			/*
			async deleteExistingPick(pick) {
				this.isStartedRowDeleteAction = true;
				let canDelete = false;
				await this.confirmYesNo(this.confirmDeleteTitle, this.confirmDeleteMessage)
					.then((data) => { canDelete = data })
				if (canDelete == true) {
					await this.deletePick(pick.id)
				}
				await this.getAllTablesData(false);
				this.isStartedRowDeleteAction = false;
			},
			*/
			// Actions
			async onReadedBarcode(barcode,type) {
				this.lastBarcode = barcode;
				this.writeLog(barcode, type);
				await this.committedBarcode();
			},
			async onReadedTag(tag,type) {
				this.lastTag = tag;
				this.writeLog(tag, type);
				await this.committedTag();
			},
			onReaderError(data,error) {
				this.showMsgError(data, error);
				this.showMsgWarning(this.messageTitleReader, this.errorReadingReader);
			},
			changedSearch(value) {
				this.fields[this.field.search].value = value;
			},
			changedTextField(fieldId, value) {
				this.pickContents[fieldId].value = value;
				(!value || value.length == 0)? this.pickContents[fieldId].validationState = false : this.pickContents[fieldId].validationState = true
			},
			changedCheckboxField(fieldId, value) {
				this.pickContents[fieldId].value = value;
				(!value || value.length == 0)? this.pickContents[fieldId].validationState = false : this.pickContents[fieldId].validationState = true
			},
			changedNewItemTextField(fieldId, value) {
				this.itemContents[fieldId].value = value;
				(!value || value.length == 0)? this.itemContents[fieldId].validationState = false : this.itemContents[fieldId].validationState = true
			},
			changedNewItemCheckboxField(fieldId, value) {
				this.itemContents[fieldId].value = value;
				(!value || value.length == 0)? this.itemContents[fieldId].validationState = false : this.itemContents[fieldId].validationState = true
			},
			assignedSelectField(fieldId, value) {
				this.itemContents[fieldId].value = value;
				(!value)? this.itemContents[fieldId].validationState = false : this.itemContents[fieldId].validationState = true
				this.itemContents[this.itemContent.code].value = value.code;
				this.itemContents[this.itemContent.description].value = value.description
				this.itemContents[this.itemContent.expectedQuantity].value = value.quantity
				this.itemContents[this.itemContent.quantity].value = null
			},
			resetSelectField(fieldId) {
				this.itemContents[fieldId].value = this.itemContents[fieldId].defaultValue;
				this.itemContents[fieldId].validationState = false
				this.itemContents[this.itemContent.code].value = this.itemContents[this.itemContent.code].defaultValue;
				this.itemContents[this.itemContent.description].value = this.itemContents[this.itemContent.description].defaultValue;
				this.itemContents[this.itemContent.expectedQuantity].value = this.itemContents[this.itemContent.expectedQuantity].defaultValue;
				this.itemContents[this.itemContent.quantity].value = null
			},
			prepareNewPickFromItems() {
				let myRecord = {
					pick: {
						project_id: this.projectId, // this.tablesData.currentProject.id
						document_id: this.documentId, // this.tablesData.currentLocation.id
						location_id: this.locationId, // this.tablesData.currentLocation.id
						item_id: this.itemContents[this.itemContent.search].value.id,
						quantity: this.itemContents[this.itemContent.expectedQuantity].value,
					}
				};
				return myRecord;
			},
			prepareNewPickFromPicks() {
				let myRecord = {
					pick: {
						project_id: this.projectId, // this.tablesData.currentProject.id
						document_id: this.documentId, // this.tablesData.currentLocation.id
						location_id: this.locationId, // this.tablesData.currentLocation.id
						item_id: this.tablesData.currentItem.id,
						quantity: this.pickContents[this.pickContent.quantity].value,
					}
				};
				return myRecord;
			},
			prepareNewProjectRowFromItems() {
				let myRecord = {
					project_rows: {
						project_id: this.projectId, // this.tablesData.currentProject.id
						document_id: this.documentId, // this.tablesData.currentLocation.id
						location_id: this.locationId, // this.tablesData.currentLocation.id
						pick_id: this.tablesData.currentPick.id,
						item_id: this.itemContents[this.itemContent.search].value.id,
						quantity: this.itemContents[this.itemContent.quantity].value,
					}
				};
				return myRecord;
			},
			prepareNewProjectRowFromPicks() {
				let myRecord = {
					project_rows: {
						project_id: this.projectId, // this.tablesData.currentProject.id
						document_id: this.documentId, // this.tablesData.currentLocation.id
						location_id: this.locationId, // this.tablesData.currentLocation.id
						pick_id: this.tablesData.currentPick.id,
						item_id: this.itemContents[this.itemContent.search].value.id,
						quantity: this.itemContents[this.itemContent.quantity].value,
					}
				};
				return myRecord;
			},
			prepareUpdateProjectRow() {
				let myRecord = {
					project_rows: {
						quantity: parseFloat(this.tablesData.currentProjectRow.quantity) + parseFloat(this.pickContents[this.pickContent.quantity].value),
					}
				};
				return myRecord;
			},
			updatePickContents(row) {
				Object.getOwnPropertyNames(row).forEach(rowField => {
					this.pickContents.forEach(pageElement => {
						if (rowField == pageElement.name) {
							pageElement.value = row[rowField];
						}
					});
				});
			},
			createCustomPickContents(projectRow = null) {
				this.pickContents[this.pickContent.code].value = (!projectRow) ? this.tablesData.currentItem.code : projectRow.item.code
				this.pickContents[this.pickContent.description].value = (!projectRow) ? this.tablesData.currentItem.description : projectRow.item.description
				this.pickContents[this.pickContent.expectedQuantity].value = (!projectRow) ? this.tablesData.currentItem.quantity : projectRow.item.quantity
				this.pickContents[this.pickContent.expectedQuantity].furtherInfo = (!projectRow) ? null : ((projectRow.quantity > 0) ? ' (già inseriti: ' + parseFloat(projectRow.quantity) + ')' : null)
				this.pickContents[this.pickContent.quantity].value = (!this.assignRemaingQuantity)? null : ((!projectRow) ? this.tablesData.currentItem.quantity : (parseFloat(this.tablesData.currentItem.quantity) - parseFloat(projectRow.quantity)))
				//this.pickContents[this.pickContent.quantity].furtherInfo = (!projectRow) ? ' / ' + this.tablesData.currentItem.quantity : ' / ' + (parseFloat(this.tablesData.currentItem.quantity) - parseFloat(projectRow.quantity))
			},
			updateCustomPickContentsWithProjectRow(row) {
				this.pickContents[this.pickContent.id].value = row.id
				this.pickContents[this.pickContent.code].value = row.item.code
				this.pickContents[this.pickContent.description].value = row.item.description
				this.pickContents[this.pickContent.expectedQuantity].value = parseInt(row.item.quantity)
				this.pickContents[this.pickContent.quantity].value = parseInt(row.quantity)
			},
			updateCustomPickContents(row) {
				this.pickContents[this.pickContent.id].value = row.project_rows[0].id
				this.pickContents[this.pickContent.code].value = row.item.code
				this.pickContents[this.pickContent.description].value = row.item.description
				this.pickContents[this.pickContent.expectedQuantity].value = parseInt(row.quantity)
				this.pickContents[this.pickContent.expectedQuantity].furtherInfo = (row.quantity_detected > 0) ? ' (già inseriti: ' + parseFloat(row.quantity_detected) + ')' : null
				this.pickContents[this.pickContent.quantity].value = (!this.assignRemaingQuantity)? null : parseFloat(row.quantity_remaining)
			},
			async createNewPickAndProjectRowFromItems(currentItem) {
				if (this.tablesData.items.length == 0 && !currentItem) {
					this.isWaitingItems = true;
					await this.getItems();
					this.isWaitingItems = false;
				}
				this.itemContents.forEach(element => element.value = element.defaultValue);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.newItemText;
				this.actionDbCreate = true;
				this.openNewItem(currentItem, false);
			},
			async editExistingPick(pick) {
				if (!this.isStartedRowDeleteAction) {
					await this.getPick(pick.id);
					await this.getProjectRow(pick.project_rows[0].id);
					this.updateCustomPickContents(pick);
					this.pickContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
					this.modalTitle = this.editItemText // + pick.item.code;
					this.actionDbCreate = false;
					this.openModal(false);
				}
			},
			async editExistingProjectRow(pick) {
				if (!this.isStartedRowDeleteAction) {
					await this.getPick(pick.id);
					await this.getProjectRow(pick.project_rows[0].id);
					this.updateCustomPickContents(pick);
					this.pickContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
					this.modalTitle = this.editItemText // + projectRow.item.code;
					this.actionDbCreate = false;
					this.openModal(false);
				}
			},
			async showExistingPick(pick) {
				if (!this.isStartedRowDeleteAction) {
					await this.getPick(pick.id);
					await this.getProjectRow(pick.project_rows[0].id);
					this.updateCustomPickContents(pick);
					this.pickContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
					this.modalTitle = this.showItemText // + pick.item.code;
					this.actionDbCreate = false;
					this.openModal(true);
				}
			},
			async showExistingProjectRow(pick) {
				if (!this.isStartedRowDeleteAction) {
					await this.getPick(pick.id);
					await this.getProjectRow(pick.project_rows[0].id);
					this.updateCustomPickContents(pick);
					this.pickContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
					this.modalTitle = this.showItemText // + projectRow.item.code;
					this.actionDbCreate = false;
					this.openModal(true);
				}
			},
			openModal(isReadOnly) {
				this.modalReadOnly = isReadOnly;
				this.isOpenModal = true;
			},
			async closeModal(saveRecord) {
				let canCloseModal = true;
				this.isActingDb = true;
				if (saveRecord == true) {
					if (this.checkQuantity) {
						if (this.actionDbCreate == true) {
							await this.createPick(this.prepareNewPickFromPicks(), false)
							await this.createProjectRow(this.prepareNewProjectRowFromPicks(), false)
						}
						else {
							await this.updateProjectRow(this.tablesData.currentProjectRow.id, this.prepareUpdateProjectRow(), false) //await this.updatePick(this.pickContents[this.pickContent.id].value, this.prepareUpdatePickQuantity(), false)
						}
					}
					else {
						canCloseModal = false;
					}
				}
				if (canCloseModal) {
					await this.getAllTablesData(false);
					this.isOpenModal = false;
				}
				this.isActingDb = false;
			},
			async modalDismissed() {
				this.actionDbCreate == null;
			},
			async openAlert(tableRow) {
				this.isStartedRowDeleteAction = true;
				this.tablesData.currentPick = tableRow;
				await this.getPick(tableRow.id);
				await this.getProjectRow(tableRow.project_rows[0].id);
				this.isOpenAlert = true;
			},
			async closeAlert(deleteRecord) {
				if (deleteRecord == true) {
					for (let i = 0; i < this.tablesData.currentPick.project_rows.length; i++) {
						await this.deleteProjectRow(this.tablesData.currentPick.project_rows[i].id, false)
					}
					await this.deletePick(this.tablesData.currentPick.id, false)
					await this.getAllTablesData(false);
				}
				this.isStartedRowDeleteAction = false;
				this.isOpenAlert = false;
			},
			async alertDismissed() {
				this.actionDbCreate = null;
			},
			openNewItem(currentItem, isReadOnly) {
				if (currentItem != null) {
					this.assignedSelectField(this.itemContents[this.itemContent.search].id, currentItem)
					this.itemContents[this.itemContent.search].enabled = false
				}
				else {
					this.itemContents[this.itemContent.search].enabled = true
				}
				this.modalReadOnly = isReadOnly;
				this.isOpenNewItem = true;
			},
			async closeNewItem(saveRecord) {
				let canCloseNewItem = true;
				this.isActingDb = true;
				if (saveRecord == true) {
					if (this.checkQuantity) {
						if (this.actionDbCreate == true) {
							await this.createPick(this.prepareNewPickFromItems(), false)
							await this.createProjectRow(this.prepareNewProjectRowFromItems(), false)
						}
						else {
							await this.updateProjectRow(this.pickContents[this.pickContent.id].value, this.prepareUpdateProjectRow(), false) //await this.updatePick(this.pickContents[this.pickContent.id].value, this.prepareUpdatePickQuantity(), false)
						}
					}
					else {
						canCloseNewItem = false;
					}
				}
				if (canCloseNewItem) {
					await this.getAllTablesData(false);
					this.isOpenNewItem = false;
				}
				this.isActingDb = false;
			},
			async newItemDismissed() {
				this.actionDbCreate == null;
			},
			changedBarcode(value) {
				this.lastBarcode = value;
			},
			async searchBarcodeInProjectPicks() {
				let ret = null;
				this.tablesData.picks.some(element => {
					if (element.item.barcode == this.lastBarcode) {
						ret = element;
						return true;
					}
					if (element.item.additional_barcodes.length > 0) {
						element.item.additional_barcodes.some(subElement => {
							if (subElement.code == this.lastBarcode) {
								ret = element;
								return true;
							}
						})
					}
				});
				return ret;
			},
			async searchBarcodeInTables() {
				let ret = null;
				this.tablesData.currentItem = null;
				this.tablesData.currentAdditionalBarcode = null;
				await this.searchItem(null,this.lastBarcode);
				if (!this.tablesData.currentItem) {
					await this.searchAdditionalBarcode(this.lastBarcode);
					if (!this.tablesData.currentAdditionalBarcode) {
						this.showMsgWarning(this.messageSearchItemTitle, this.messageSearchItemNotFound + this.lastBarcode);
					}
					else {
						ret = this.tablesData.currentAdditionalBarcode.item;
					}
				}
				else {
					ret = this.tablesData.currentItem;
				}
				return ret;
			},
			async committedBarcode() {
				let foundElement = null;
				if (this.isOpenModal == false && this.isOpenNewItem == false && this.isOpenAlert == false) {
					foundElement = await this.searchBarcodeInProjectPicks();
					if (!foundElement) {
						foundElement = await this.searchBarcodeInTables();
						if (foundElement != null) {
							await this.createNewPickAndProjectRowFromItems(foundElement);
						}
					}
					else {
						await this.editExistingProjectRow(foundElement);
					}
				}
			},
			changedTag(value) {
				this.lastTag = value;
			},
			async committedTag() {
				let tagElements = this.lastTag.split('|');
				let foundElement = null;

				switch (tagElements.length) {
					case 0:
						this.lastTag = null;
						this.showMsgWarning(this.messageTitleCheckTAG,this.warnInavlidTAG);
						break;
					case 1:
						this.tablesData.projectRows.some(projectRow => {
							if (projectRow.item.code == this.lastTag) {
								foundElement = projectRow;
								return true;
							}
						});
						if (!foundElement) this.showMsgWarning(this.messageSearchItemTitle, this.messageSearchItemNotFound);
						else this.editExistingProjectRow(foundElement);
						break;
					default:
						this.lastTag = null;
						this.showMsgWarning(this.messageTitleCheckTAG,this.warnMultipleTAG);
						break;
				}
			},
			getDocumentStatus() {
				let status = {
					closed: true,
					untouched: true,
				};
				this.tablesData.picks.forEach(element => {
					if (parseFloat(element.quantity_detected) > 0) status.untouched = false;
					if (!this.allowExceededQuantity) {
						if (parseFloat(element.quantity) != parseFloat(element.quantity_detected)) status.closed = false;
					}
					else {
						if (parseFloat(element.quantity) > parseFloat(element.quantity_detected)) status.closed = false;
					}
				});
				return status;
			},
			async updateDocumentFlags(closedFlag = null, partialFlag = null) {
				let myRecord = {
					document: {
					}
				};
				if (closedFlag != null) myRecord.document['closed'] = closedFlag
				if (partialFlag != null) myRecord.document['partial'] = partialFlag
				await this.updateDocument(this.documentId, myRecord, false);
			},
			async closeInventoryDocument() {
				let canExit = true;
				let projectsStatus = this.getDocumentStatus();

				if (projectsStatus.closed == true) {
					await this.updateDocumentFlags(true, false);
				}
				else if (projectsStatus.untouched == true) {
					canExit = false;
				}
				else {
					await this.confirmYesNo(this.confirmExitTitle, this.confirmCloseMessage)
					.then((data) => { canExit = data })
					if (canExit == true) {
						await this.updateDocumentFlags(true, false);
					}
				}

				if (canExit == true) {
					this.$router.push("/inventory/selection/" + this.projectId + "?free=" + this.isFree + "&picking="+this.isPicking);
				}
			},
			async exitInventoryDocument() {
				let canExit = true;
				let projectStatus = this.getDocumentStatus();

				if (projectStatus.untouched == true) {
					await this.updateDocumentFlags(false, false);
				}
				else {
					await this.confirmYesNo(this.confirmExitTitle, this.confirmExitMessage)
					.then((data) => { canExit = data })
					if (canExit == true) {
						await this.updateDocumentFlags(false, true);
					}
				}

				if (canExit == true) {
					this.$router.push("/inventory/selection/" + this.projectId + "?free=" + this.isFree + "&picking="+this.isPicking);
				}
			},
			/*
			async OldExitProject() {
				let canExit = true;
				let myRecord = {
					document: {}
				};
				
				if (this.isInventoryFinished == true) {
					myRecord.document['partial'] = false;
					myRecord.document['closed'] = true;
					await this.updateDocument(this.documentId, myRecord);
				}
				else {
					if (this.isInventoryClean != true) {
						myRecord.document['partial'] = true;
						await this.updateDocument(this.documentId, myRecord);
						await this.confirmYesNo(this.confirmExitTitle, this.confirmExitMessage)
						.then((data) => { canExit = data })
					} else {
						myRecord.document['closed'] = false;
						myRecord.document['partial'] = false;
						await this.updateDocuemnt(this.documentId, myRecord);
					}
				}

				if (canExit == true) {
					this.$router.push("/inventory/selection/" + this.projectId + "?free=" + this.isFree + "&picking=" + this.isPicking);
				}
			}
			*/
		}
	});
</script>
