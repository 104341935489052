<template>
	<ion-page>
		<spot-header :title="pageTitle" :canGoHome="true" :canGoBack="false" @pressedGoHome="$router.push('/home')" @pressedGoBack="exitSelection"></spot-header>

		<ion-content :fullscreen="true"> <!--v-if="isActive"-->
			<ion-progress-bar type="indeterminate" v-if="!isActive"></ion-progress-bar>
			<div id="actionArea" v-else>
				<ion-item lines="none" class="no-pad">
					<spot-input
						:ref="fields[field.search].name"
						:label="fields[field.search].text"
						:allignLabel="fields[field.search].allignLabel"
						:required="fields[field.search].required"
						:placeholder="fields[field.search].placeholder"
						:enabled="true"
						:value="fields[field.search].value"
						:type="fields[field.search].inputType"
						:clearInput="true"
						:canRefresh="fields[field.search].canRefresh"
						@refresh="this.$router.push('/store/selection' + '?picking=' + this.isPicking)"
						@textChanged="changedSearch">
					</spot-input>
					<ion-button v-if="!isPicking" size="default" slot="end" disabled=false @click="createNewProject">
						<ion-icon :icon="duplicate" slot="start" size="default"></ion-icon>
						{{ newSupplyText }}
					</ion-button>
				</ion-item>
				<ion-progress-bar type="indeterminate" v-if="!tablesData.projects"></ion-progress-bar>
				<EasyDataTable
					:headers="headers"
					:items="tablesData.projects"
					:sort-by="sortBy"
					:sort-type="sortType"
					:search-field="searchFields"
					:search-value="fields[field.search].value"
					:empty-message="noSupplyText"
					:body-row-class-name="bodyRowClassNameFunction"
					@click-row="selectedExistingProject"
					multi-sort
				><!-- :body-item-class-name="bodyItemClassNameFunction" :body-expand-row-class-name="bodyExpandRowClassNameFunction" show-index alternating -->
					<template #item-actions="project">
						<!--
						<ion-button :color="project.closed || project.prepared || project.delivered ? 'danger' : project.partial ? 'warning' : 'success'" :disabled="project.closed || project.prepared || project.delivered" fill="outline" shape="round" size="small" slot="icon-only" @click="selectedExistingProject(project)">
							<ion-icon :icon="enter" slot="end" size="small"></ion-icon>
						</ion-button>
						<ion-button disabled=false color="dark" fill="outline" shape="round" size="small" slot="icon-only" @click="showExistingProject(project)">
							<ion-icon :icon="reader" slot="end" size="small"></ion-icon>
						</ion-button>
						<ion-button :disabled=isPicking color="dark" fill="outline" shape="round" size="small" slot="icon-only" @click="editExistingProject(project)">
							<ion-icon :icon="brush" slot="end" size="small"></ion-icon>
						</ion-button>
						-->
						<ion-button disabled=false color="dark" fill="outline" shape="round" size="small" slot="icon-only" @click="openAlert(project)"><!-- @click="deleteExistingProject(project)" -->
							<ion-icon :icon="trash" slot="end" size="small"></ion-icon>
						</ion-button>
					</template>
				</EasyDataTable>
				<ion-modal class="modal-settings" ref="modal" backdrop-dismiss="false" :is-open="isOpenModal" @didDismiss="modalDismissed">
					<ion-content>
						<ion-toolbar>
							<ion-title> {{ modalTitle }}</ion-title>
						</ion-toolbar>
						<div v-for="field in orderContents" :key="field.id">
							<spot-input
								v-if="(field.inputType == 'text' || field.inputType == 'number') && field.visible"
								:ref="field.name"
								:label="field.text"
								:allignLabel="field.allignLabel"
								:required="field.required"
								:placeholder="getPlaceHolder(field)"
								:enabled="field.enabled && !modalReadOnly"
								:validationState="field.validationState"
								:value="field.value"
								:type="field.inputType"
								@textChanged="changedTextField(field.id, $event)">
							</spot-input>
							<ion-textarea
								v-if="field.inputType == 'area' && field.visible"
								:ref="field.name"
								:required="field.required"
								:placeholder="getPlaceHolder(field)"
								:disabled="!field.enabled || modalReadOnly"
								:auto-grow="true"
								:value="field.value">
							</ion-textarea>
							<spot-checkbox
								v-if="(field.inputType == 'check') && field.visible"
								:ref="field.name"
								:label="field.text"
								:allignLabel="field.allignLabel"
								:required="field.required"
								:enabled="field.enabled && !modalReadOnly"
								:validationState="field.validationState"
								:value="field.value"
								@checkboxChanged="changedCheckboxField(field.id, $event)">
							</spot-checkbox>
							<spot-date
								v-if="(field.inputType == 'datetime') && field.visible"
								:ref="field.name"
								:label="field.text"
								:allignLabel="field.allignLabel"
								:required="field.required"
								:enabled="field.enabled && !modalReadOnly"
								:validationState="field.validationState"
								:value="field.value"
								color="primary"
								:doneText="confirmText"
								:cancelText="undoText"
								@dateChanged="changedDateField(field.id, $event)">
							</spot-date>
						</div>
						<div class="flex-row-center-container">
							<spot-button
								fill="outline"
								size="small"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="undoText"
								@clicked="closeModal(false)">
							</spot-button>
							<spot-button
								fill="outline"
								size="small"
								color="primary"
								:enabled="!isEmptyRequiredFields && modalReadOnly == false"
								:expand="null"
								:text="confirmText"
								@clicked="closeModal(true)">
							</spot-button>
						</div>
					</ion-content>
				</ion-modal>
				<ion-modal class="alert-settings" ref="alert" backdrop-dismiss="false" :is-open="isOpenAlert" @didDismiss="alertDismissed">
					<ion-content>
						<ion-toolbar>
							<ion-title> {{ confirmDeleteTitle }}</ion-title>
						</ion-toolbar>
						<div class="wrapper">
							<ion-label class="ion-text-wrap">{{ confirmDeleteMessage }}</ion-label>
							<br>
							<ion-label class="ion-text-wrap">{{	!!tablesData.currentProject ? tablesData.currentProject.name : ''}}</ion-label>
						</div>
						<div class="flex-row-center-container">
							<spot-button
								fill="outline"
								size="small"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="undoText"
								@clicked="closeAlert(false)">
							</spot-button>
							<spot-button
								fill="outline"
								size="small"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="confirmText"
								@clicked="closeAlert(true)">
							</spot-button>
						</div>
					</ion-content>
				</ion-modal>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>Test Area</div>
				<spot-button
					fill="outline"
					size="large"
					color="primary"
					:enabled="true"
					text="Simulate Scan"
					@clicked="onReadedBarcode('1111111111111','barcode')">
				</spot-button>
				<div v-for="field in fields" :key="field.id">
					{{ field.name }} = {{ field.value }}
				</div>
			</div>
		</ion-content>

	</ion-page>
</template>

<style scoped>
	.modal-settings {
		--height: 80%;
		--width: 90%;
		--border-radius: 16px;
		--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	}
	.alert-settings {
		--height: 50%;
		--width: 90%;
		--border-radius: 16px;
		--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	}
	.wrapper {
		padding-left: 3vw;
		padding-right: 3vw;
	}
	ion-modal::part(backdrop) {
		background: var(--ion-color-medium);
		opacity: 1;
	}
	ion-modal ion-toolbar {
		--background: var(--ion-color-primary);
		--color: white;
	}
	.flex-row-center-container {
		margin-top: 0.2em;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.no-pad {
		margin-top: 0px;
		margin-bottom: 0px;
		--padding-top: 0px;
		--padding-bottom: 0px;
		--inner-padding-top: 0px;
		--inner-padding-bottom: 0px;
		--min-height: 0px
	}
	*:deep(ion-checkbox) {
		margin-top: 0px;
		margin-bottom: 0px;
	}
</style>
<style>
	.closed-row {
		--easy-table-body-row-background-color: var(--ion-color-danger);
	}
	.partial-row {
		--easy-table-body-row-background-color: var(--ion-color-warning);
	}
	.new-row {
		/* --easy-table-body-row-background-color: var(--ion-color-success);*/
		--easy-table-body-row-background-color: var(--ion-color-light);
	}
	.suspended-row {
		--easy-table-body-row-background-color: var(--ion-color-tertiary);
	}
	.expanded-row {
		--easy-table-body-row-background-color: var(--ion-color-medium);
	}
	.prominence-column {
		font-size: 20px;
	}
</style>

<script>
import { IonButton, IonContent, IonIcon, IonItem, IonLabel, IonModal, IonPage, IonProgressBar, IonTextarea, IonTitle, IonToolbar } from '@ionic/vue';
import { brush, duplicate, enter, reader, trash } from 'ionicons/icons';
import { defineComponent } from 'vue';
import SpotButton from '../components/SpotButton.vue';
import SpotCheckbox from '../components/SpotCheckbox.vue';
import SpotInput from '../components/SpotInput.vue';
import SpotHeader from "../components/SpotHeader.vue";
import SpotDate from "../components/SpotDate.vue";
import { /* checkUserSession, */ confirmYesNo /*, insertValue*/, settings, showMsgError, showMsgInfo, showMsgWarning, writeLog } from '../plugins/common.js';
import { createProject, deleteProject, getProject, searchProjectTypology, searchProjects, tablesData, updateProject } from '../plugins/customDatabase.js';

	export default defineComponent({
		name: "SelectStoreSupply",
		props: {
			pageTitle: { type: String, default: 'Seleziona Approvvigionamento' },
			storeSupplyTitle: { type: String, default: 'AN' }, //placeholder.picking_list
			undoText: { type: String, default: 'Annulla' },
			confirmText: { type: String, default: 'Conferma' },
			unselected: { type: String, default: 'Nessuno' },
			noSupplyText: { type: String, default: 'Nessun Approvvigionamento presente' },
			newSupplyText: { type: String, default: 'Nuovo' }, //'Nuovo Approvvigionamento'
			editSupplyText: { type: String, default: 'Modifica Approvvigionamento'}, //'Modifica Ordine: ' / 'Modifica: '
			showSupplyText: { type: String, default: 'Visualizza Approvvigionamento'}, //'Visualizza Ordine: ' / 'Modifica: '
			confirmDeleteTitle: { type: String, default: 'Conferma Cancellazione' },
			confirmDeleteMessage: { type: String, default: 'Confermi la cancellazione dell\'approvvigionamento corrente?' },
		},
		components: {
			IonPage,
			IonContent,
			IonProgressBar,
			IonItem,
			IonButton,
			IonIcon,
			IonModal,
			IonToolbar,
			IonTitle,
			IonTextarea,
			IonLabel,
			SpotHeader,
			SpotInput,
			SpotCheckbox,
			SpotDate,
			SpotButton,
		},
		data: () => ({
			isActive: null,
			field: {
				search: 0,
			},
			fields: [
				{ id: 0, name: 'search', text: 'Cerca: ', allignLabel: true, placeholder: 'Cerca Lista', inputType: 'text', defaultValue: '', value: null, required: false, refField: null, canRefresh: true },
			],
			searchFields: ['name'],
			headers: [
				{ text: 'Nome', value: 'name', sortable: true },
				{ text: 'Data', value: 'project_date', sortable: true },
				{ text: 'Azioni', value: 'actions', sortable: false }
			],
			sortType: ['desc', 'desc'],
			sortBy: ['suspended', 'updated_at'/*'name'*/],
			orderContent: {
				id: 0,
				name: 1,
				projectDate: 2,
				// description: 2,
				// projectDate: 3,
				// shippingDate: 4,
				// customer: 5,
				// customerInfo: 6,
				// supplier: 7,
				// supplierInfo: 8,
				// address: 9,
				// addressInfo: 10,
				// agent: 11,
				// packages: 12,
				// operator: 13,
				// payment: 14,
				// plant: 15,
				// closed: 16,
				// partial: 17,
				// prepared: 18,
				// delivered: 19,
				// suspended: 20,
			},
			orderContents: [
				{ id: 0, name: 'id', text: 'Id', placeholder: 'Inserisci ID', inputType: "number", enabled: false, visible: false, allignLabel: true, validationState: null, defaultValue: null, value: null, required: true },
				{ id: 1, name: 'name', text: 'Nome', placeholder: 'Inserisci Nome Approvvigionamento', inputType: "text", enabled: true, visible: true, allignLabel: true, validationState: null, defaultValue: null, value: null, required: true },
				{ id: 2, name: 'projectDate', text: 'Data Progetto', placeholder: 'Inserisci Data Progetto', inputType: "datetime", enabled: true, visible: true, allignLabel: true, validationState: null, defaultValue: new Date().toISOString(), value: null, required: true },
				// { id: 2, name: 'description', text: 'Note', placeholder: 'Inserisci Note', inputType: "text", enabled: false, visible: true, allignLabel: false, validationState: null, defaultValue: null, value: null, required: true },
				// { id: 3, name: 'projectDate', text: 'Data Progetto', placeholder: 'Inserisci Data Progetto', inputType: "text", enabled: false, visible: true, allignLabel: true, validationState: null, defaultValue: null, value: null, required: true },
				// { id: 4, name: 'shippingDate', text: 'Data Prevista', placeholder: 'Inserisci Data Prevista', inputType: "text", enabled: false, visible: true, allignLabel: true, validationState: null, defaultValue: null, value: null, required: true },
				// { id: 5, name: 'customer', text: 'Cliente', placeholder: 'Inserisci Cliente', inputType: "text", enabled: false, visible: true, allignLabel: false, validationState: null, defaultValue: null, value: null, required: false },
				// { id: 6, name: 'customerInfo', text: 'Dettagli Cliente', placeholder: 'Inserisci Dettagli Cliente', inputType: "area", enabled: false, visible: true, allignLabel: false, validationState: null, defaultValue: null, value: null, required: false },
				// { id: 7, name: 'supplier', text: 'Fornitore', placeholder: 'Inserisci Fornitore', inputType: "text", enabled: false, visible: true, allignLabel: false, validationState: null, defaultValue: null, value: null, required: false },
				// { id: 8, name: 'supplierInfo', text: 'Dettagli Fornitore', placeholder: 'Inserisci Dettagli Fornitore', inputType: "area", enabled: false, visible: true, allignLabel: false, validationState: null, defaultValue: null, value: null, required: false },
				// { id: 9, name: 'address', text: 'Destinazione', placeholder: 'Inserisci Destinazione', inputType: "text", enabled: false, visible: true, allignLabel: false, validationState: null, defaultValue: null, value: null, required: false },
				// { id: 10, name: 'addressInfo', text: 'Dettagli Destinazione', placeholder: 'Inserisci Dettagli Destinazione', inputType: "area", enabled: false, visible: true, allignLabel: false, validationState: null, defaultValue: null, value: null, required: false },
				// { id: 11, name: 'agent', text: 'Consegna Agente', inputType: "check", enabled: false, visible: true, allignLabel: true, validationState: null, defaultValue: false, value: null, required: false },
				// { id: 12, name: 'packages', text: 'Nr. Colli', placeholder: 'Inserisci Nr. Colli', inputType: "number", enabled: false, visible: true, allignLabel: true, validationState: null, defaultValue: null, value: null, required: true },
				// { id: 13, name: 'operator', text: 'Operatore', placeholder: 'Inserisci Operatore', inputType: "text", enabled: false, visible: true, allignLabel: true, validationState: null, defaultValue: null, value: null, required: true },
				// { id: 14, name: 'payment', text: 'Codice Pagamento', placeholder: 'Inserisci Codice Pagamento', inputType: "text", enabled: false, visible: true, allignLabel: true, validationState: null, defaultValue: null, value: null, required: true },
				// { id: 15, name: 'plant', text: 'Zona', placeholder: 'Inserisci Zona', inputType: "text", enabled: false, visible: true, allignLabel: true, validationState: null, defaultValue: null, value: null, required: true },
				// { id: 16, name: 'closed', text: 'Chiuso', inputType: "check", enabled: false, visible: false, allignLabel: true, validationState: null, defaultValue: false, value: null, required: false },
				// { id: 17, name: 'partial', text: 'Parziale', inputType: "check", enabled: false, visible: false, allignLabel: true, validationState: null, defaultValue: false, value: null, required: false },
				// { id: 18, name: 'prepared', text: 'Preparato', inputType: "check", enabled: false, visible: false, allignLabel: true, validationState: null, defaultValue: false, value: null, required: false },
				// { id: 19, name: 'delivered', text: 'Spedito', inputType: "check", enabled: false, visible: false, allignLabel: true, validationState: null, defaultValue: false, value: null, required: false },
				// { id: 20, name: 'suspended', text: 'Sospeso', inputType: "check", enabled: false, visible: false, allignLabel: true, validationState: null, defaultValue: false, value: null, required: false },
			],
			isOpenModal: false,
			isOpenAlert: false,
			modalTitle: null,
			modalReadOnly: null,
			actionDbCreate: null,
			isPicking: null,
			isStartedRowAction: false,
			duplicate,
			enter,
			reader,
			trash,
			brush,
			settings,
			confirmYesNo,
			showMsgError,
			showMsgInfo,
			showMsgWarning,
			writeLog,
			searchProjectTypology,
			searchProjects,
			getProject,
			createProject,
			updateProject,
			deleteProject,
			tablesData,
		}),
		async ionViewWillEnter() {
			this.getUrlParamsAndQuery();
			this.setDefault();
			await this.getAllTablesData();
			this.isActive = true;
		},
		ionViewWillLeave() {
			this.isOpenModal = false;
			this.isOpenAlert = false;
			this.isActive=false;
		},
		computed: {
			isEmptyRequiredFields() {
				let ret = false;
				this.orderContents.forEach(element => {if (element.required && !element.value && element.id > 0) ret=true});
				return ret;
			},
		},
		setup() {
			const bodyRowClassNameFunction = (element) => {
				if (element.closed == true || element.prepared == true || element.delivered == true ) return 'closed-row'
				else if(element.partial == true) return 'partial-row'
				else if(element.suspended == true) return 'suspended-row'
				else return 'new-row';
			}
			const bodyItemClassNameFunction = (column) => {
				if (column === 'name') return 'prominence-column';
				else return '';
			};
			const bodyExpandRowClassNameFunction = () => {
				return 'expanded-row';
			};
			return {
				bodyRowClassNameFunction,
				bodyItemClassNameFunction,
				bodyExpandRowClassNameFunction,
			};
		},
		methods: {
			// Settings & StartUp
			getUrlParamsAndQuery() {
				this.isPicking = (/true/i).test(this.$route.query.picking);
			},
			setDefault() {
				this.fields.forEach(element => element.value = element.defaultValue);
				this.isOpenModal = false;
				this.isOpenAlert = false;
				this.modalTitle = null;
				this.modalReadOnly = null;
				this.actionDbCreate = null;
				this.isStartedRowAction = false;
			},
			getPlaceHolder(element) {
				return ((this.settings.showFieldsPlaceholder) ? (element.required ? element.placeholder : this.unselected ) : '')
			},
			// Database
			async getAllTablesData() {
				this.tablesData.projects = [];
				this.tablesData.currentProjectTypology = null;
				await this.searchProjectTypology(this.storeSupplyTitle);
				await this.searchProjects(this.tablesData.currentProjectTypology.id, this.isPicking, false, false, false, null/*false*/, null/*false*/);
			},
			// Actions
			changedSearch(value) {
				this.fields[this.field.search].value = value;
			},
			changedTextField(fieldId, value) {
				this.orderContents[fieldId].value = value;
				(!value || value.length == 0)? this.orderContents[fieldId].validationState = false : this.orderContents[fieldId].validationState = true
			},
			changedCheckboxField(fieldId, value) {
				this.orderContents[fieldId].value = value;
				(!value || value.length == 0)? this.orderContents[fieldId].validationState = false : this.orderContents[fieldId].validationState = true
			},
			changedDateField(fieldId, value) {
				this.orderContents[fieldId].value = value;
				(!value || value.length == 0)? this.orderContents[fieldId].validationState = false : this.orderContents[fieldId].validationState = true
			},
			prepareNewProject() {
				let myRecord = {
					project: {
						name: this.orderContents[this.orderContent.name].value,
						project_date: this.orderContents[this.orderContent.projectDate].value,
						project_typology_id: this.tablesData.currentProjectTypology.id,
						branch_id: 1,
						serie: 0,
						year: new Date().getFullYear(),
						external_code: this.orderContents[this.orderContent.name].value
						// description: this.orderContents[this.orderContent.description].value,
						// customer: this.orderContents[this.orderContent.customer].value,
						// supplier: this.orderContents[this.orderContent.supplier].value,
						// closed: this.orderContents[this.orderContent.closed].value,
						// partial: this.orderContents[this.orderContent.partial].value,
						// prepared: this.orderContents[this.orderContent.prepared].value,
						// delivered: this.orderContents[this.orderContent.delivered].value,
					}
				};
				return myRecord;
			},
			prepareUpdateProject() {
				let myRecord = {
					project: {
						name: this.orderContents[this.orderContent.name].value,
						project_date: this.orderContents[this.orderContent.projectDate].value,
						// description: this.orderContents[this.orderContent.description].value,
					}
				};
				return myRecord;
			},
			updateOrderContents(row) {
				Object.getOwnPropertyNames(row).forEach(rowField => {
					this.orderContents.forEach(pageElement => {
						if (rowField == pageElement.name) {
							pageElement.value = row[rowField];
						}
					});
				});
			},
			updateCustomOrderContents(row) {
				this.orderContents[this.orderContent.id].value = row.id
				this.orderContents[this.orderContent.name].value = row.name
				this.orderContents[this.orderContent.projectDate].value = (!row.project_date ? null : new Date(row.project_date))
				// this.orderContents[this.orderContent.description].value = row.description
				// this.orderContents[this.orderContent.projectDate].value = (!row.project_date ? null : new Date(row.project_date).toLocaleDateString())
				// this.orderContents[this.orderContent.shippingDate].value = (!row.estimated_shipping_date ? null : new Date(row.estimated_shipping_date).toLocaleDateString())
				// this.orderContents[this.orderContent.customer].value = (!row.customer ? null : row.customer.name)
				// this.orderContents[this.orderContent.customerInfo].value = (!row.customer ? null : (row.customer.address + "\n" + row.customer.postal_code + " " + row.customer.locality + " (" + row.customer.province + ")"))
				// this.orderContents[this.orderContent.supplier].value = (!row.supplier ? null : row.supplier.name)
				// this.orderContents[this.orderContent.supplierInfo].value = (!row.supplier ? null : (row.supplier.address + "\n" + row.supplier.postal_code + " " + row.supplier.locality + " (" + row.supplier.province + ")"))
				// this.orderContents[this.orderContent.address].value = (!row.address ? null : row.address.name)
				// this.orderContents[this.orderContent.addressInfo].value = (!row.address ? null : (row.address.address + "\n" + row.address.postal_code + " " + row.address.locality + " (" + row.address.province + ")"))
				// this.orderContents[this.orderContent.agent].value = (!row.transport_mean ? null : row.transport_mean.flag_agent)
				// this.orderContents[this.orderContent.payment].value = row.payment_code
				// this.orderContents[this.orderContent.plant].value = (!row.plant ? null : row.plant.name)
				// this.orderContents[this.orderContent.packages].value = row.package_number
				// this.orderContents[this.orderContent.operator].value = row.operator
				// this.orderContents[this.orderContent.closed].value = row.closed
				// this.orderContents[this.orderContent.partial].value = row.partial
				// this.orderContents[this.orderContent.prepared].value = row.prepared
				// this.orderContents[this.orderContent.delivered].value = row.delivered
				// this.orderContents[this.orderContent.suspended].value = row.suspended
			},
			createNewProject() {
				this.orderContents.forEach(element => element.value = element.defaultValue);
				this.orderContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.newSupllyText;
				this.actionDbCreate = true;
				this.openModal(false);
			},
			async editExistingProject(project) {
				this.updateCustomOrderContents(project);
				this.orderContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.editSupplyText // + project.name;
				this.actionDbCreate = false;
				this.openModal(false);
			},
			async showExistingProject(project) {
				this.updateCustomOrderContents(project);
				this.orderContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.showSupplyText // + project.name;
				this.actionDbCreate = false;
				this.openModal(true);
			},
			async selectedExistingProject(project) {
				if (!this.isOpenModal && !this.isStartedRowAction) {
					this.$router.push("/store/management/" + project.id + "?picking=" + this.isPicking)
				}
			},
			openModal(isReadOnly) {
				this.modalReadOnly = isReadOnly;
				this.isOpenModal = true;
			},
			async closeModal(saveRecord) {
				if (saveRecord == true) {
					if (this.actionDbCreate == true) {
						await this.createProject(this.prepareNewProject(), false)
					}
					else {
						await this.updateProject(this.orderContents[this.orderContent.id].value, this.prepareUpdateProject(), false)
					}
				}
				await this.getAllTablesData();
				this.isOpenModal = false;
			},
			async modalDismissed() {
				this.actionDbCreate == null;
			},
			async openAlert(tableRow) {
				this.isStartedRowAction = true;
				this.tablesData.currentProject = tableRow;
				await this.getProject(tableRow.id);
				this.isOpenAlert = true;
			},
			async closeAlert(deleteRecord) {
				let project_id = this.tablesData.currentProject.id
				if (deleteRecord == true) {
					await this.deleteProject(project_id, false);
					await this.getAllTablesData();
				}
				this.isStartedRowAction = false;
				this.isOpenAlert = false;
			},
			async alertDismissed() {
				this.actionDbCreate = null;
			},
			async exitSelection() {
				let canExit = !this.isOpenModal;
				
				if (canExit == true) {
					this.$router.push("/home");
				}
			}
		}
	});
</script>