<template>
 <ion-page>
		<spot-header :title="pageTitle" :canGoHome="true && !isPrinting" :canGoBack="false && !isPrinting" @pressedGoHome="$router.push('/home')" @pressedGoBack="$router.push('/home')"></spot-header>

		<ion-content :fullscreen="true"> <!--v-if="isActive"-->
			<ion-progress-bar type="indeterminate" v-if="!isActive"></ion-progress-bar>
			<div id="actionArea" v-else>
				<web-socket-reader crReplacedChar="|" @readedBarcode="onReadedBarcode" @readedTag="onReadedTag" @readerError="onReaderError"></web-socket-reader>
				<ion-label color="tertiary" v-if="isPrinting">{{isPrintingText}}</ion-label>

				<ion-modal class="modal-settings" ref="modal" backdrop-dismiss="false" :is-open="isOpenModal" @didDismiss="modalDismissed">
					<ion-content>
						<ion-toolbar>
							<ion-title> {{ modalTitle }}</ion-title>
						</ion-toolbar>
						<div v-for="field in itemContents" :key="field.id">
							<spot-input
								v-if="(field.inputType == 'text' || field.inputType == 'number')"
								:ref="field.name"
								:label="field.text"
								:allignLabel="field.allignLabel"
								:required="field.required"
								:placeholder="getPlaceHolder(field)"
								:enabled="field.enabled"
								:validationState="field.validationState"
								:furtherInfo="field.furtherInfo"
								:value="field.value"
								:type="field.inputType"
								@textChanged="changedTextField(field.id, $event)">
							</spot-input>
							<ion-textarea
								v-if="field.inputType == 'area'"
								:ref="field.name"
								:placeholder="getPlaceHolder(field)"
								:required="field.required"
								:disabled="!field.enabled"
								:auto-grow="true"
								:value="field.value">
							</ion-textarea>
							<spot-checkbox
								v-if="field.inputType == 'check'"
								:ref="field.name"
								:label="field.text"
								:allignLabel="field.allignLabel"
								:required="field.required"
								:enabled="field.enabled"
								:validationState="field.validationState"
								:value="field.value"
								@checkboxChanged="changedCheckboxField(field.id, $event)"
								@focus="$event.target.select()">
							</spot-checkbox>
							<spot-select
								v-if="field.inputType == 'select'"
								:ref="field.name"
								:label="field.text"
								:allignLabel="field.allignLabel"
								:required="field.required"
								:placeholder="getPlaceHolder(field)"
								:enabled="field.enabled"
								:enabledExtraButtons="field.enabledExtraButtons"
								:validationState="field.validationState"
								:value="field.value"
								:options="tablesData.packages"
								:optionField="field.refField"
								:canAddItem="field.canAddItem"
								:canRefreshList="field.canRefresh"
								:canUnlock="field.canUnlock"
								:selectedLabel="selectedText"
								:deselectLabel="deselectText"
								:selectLabel="selectText"
								@selected="assignedSelectField(field.id, $event)"
								@unselected="resetSelectField(field.id)"
								@addItem="addPackage">
							</spot-select>
						</div>
						<div class="flex-row-center-container">
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="undoText"
								@clicked="closeModal(false)">
							</spot-button>
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="!isEmptyRequiredFields"
								:expand="null"
								:text="confirmText"
								@clicked="closeModal(true)">
							</spot-button>
						</div>
					</ion-content>
				</ion-modal>
				<ion-modal class="alert-settings" ref="alert" backdrop-dismiss="false" :is-open="isOpenAlert" @didDismiss="alertDismissed">
					<ion-content>
						<ion-toolbar>
							<ion-title> {{ confirmDeleteTitle }}</ion-title>
						</ion-toolbar>
						<div class="wrapper">
							<ion-label class="ion-text-wrap">{{ confirmDeleteMessage }}</ion-label>
							<br>
							<ion-label class="ion-text-wrap">{{	tablesData.currentPick.item.description }}</ion-label>
						</div>
						<div class="flex-row-center-container">
							<spot-button
								fill="outline"
								size="small"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="undoText"
								@clicked="closeAlert(false)">
							</spot-button>
							<spot-button
								fill="outline"
								size="small"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="confirmText"
								@clicked="closeAlert(true)">
							</spot-button>
						</div>
					</ion-content>
				</ion-modal>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>Test Area</div>
				<spot-button
					fill="outline"
					size="large"
					color="primary"
					:enabled="true"
					text="Simulate Scan"
					@clicked="onReadedBarcode('87196429','barcode')">
				</spot-button>
				<div v-for="field in fields" :key="field.id">
					{{ field.name }} = {{ field.value }}
				</div>
			</div>
		</ion-content>

 </ion-page>
</template>
